import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import styles from "../styles/careers.module.css";
import getInTouchBtn from "../assets/images/getInTouchBtn.svg";
import rightArrow from '../assets/images/Arrow 17.svg';


// emailjs.init('FQAo_J9_7ko78d1o_');

const Careers: React.FC = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        contactNumber: '',
        linkedIn: '',
        expertise: '',
        wfh: '',
        message: '',
    });
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileName, setFileName] = useState<string | null>("No File choosen");

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the file input click
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            console.log('Selected file:', files[0]); // Handle the file as needed
            setFileName(files[0]?.name)
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleExpertiseClick = (expertise: string) => {
        setFormData({ ...formData, expertise });
    };

    const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, wfh: e.target.value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const emailData = {
            to_name: 'Team',
            from_name: formData.fullName,
            fullName: formData.fullName,
            email: formData.email,
            contactNumber: formData.contactNumber,
            linkedIn: formData.linkedIn,
            expertise: formData.expertise,
            wfh: formData.wfh,
            message: formData.message,
        };
        // Send email using EmailJS
        emailjs.send('service_ry6v48b','template_e0jl34n',emailData,'s8HlBBhcGY0-qKPKf')
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
                // Optionally, reset the form
                setFormData({
                    fullName: '',
                    email: '',
                    contactNumber: '',
                    linkedIn: '',
                    expertise: '',
                    wfh: '',
                    message: '',
                });
                setFileName("No File chosen");
            }, (err) => {
                console.error('Failed to send email. Error: ', err);
            });
    };
    return (
        <div className={styles.careers}>
            <div className={styles.container}>
                <div className={styles.left_div}>
                    <div className={styles.m_logo}><img src="/images/m_logo.png" alt="Company logo" /></div>
                    <h1 className={styles.heading}>We Hire The Good and Make Them The Best</h1>
                    <p className={styles.desc}>Be a Part of a Visionary Team Driving Cloud Innovation.</p>
                    {/* <button className={styles.cont_btn}>Get In Touch</button> */}
                    <div className={styles.cont_btn}><img src={getInTouchBtn} /></div>

                </div>

                <div className={styles.right_div}>
                    <img src="\images\careersHeroImg.png" className={styles.right_img} alt="Hero Image" />
                </div>
            </div>

            <div className={styles.hiringSkills}>
                <div className={styles.hiringHead}>
                    <p className={styles.short_heading}>We Are Hiring For</p>
                    <div className={styles.line}></div>
                </div>
                <div className={styles.inshorts_left}>
                    <h1 className={styles.inshorts_head}>Hit APPLY If  You Know Any Of  The Two Below</h1>
                </div>
                <div className={styles.gridItems}>
                    <div className={styles.gridItem1}>
                        <img src="\images\hiringGCS.png" />
                        <h3>Google Cloud Services</h3>
                    </div>
                    <div className={styles.gridItem2}>
                        <img src="\images\hiringGoLang.png" />
                        <h3>Go</h3>
                    </div>
                    <div className={styles.gridItem3}>
                        <img src="\images\hiringPython.png" />
                        <h3>Python</h3>
                    </div>
                    <div className={styles.gridItem4}>
                        <img src="\images\hiringReact.png" />
                        <h3>React</h3>
                    </div>
                </div>
            </div>

            <div className={styles.JoinUs}>
                <div className={styles.joinUsHead}>
                    <h1>Why Join Us?</h1>
                    <div className={styles.joinUsHeadp}><p>Our HR team will reach you out when there's any opening that matches your profile</p></div>
                </div>
                <div className={styles.joinUsGrid}>
                    <div className={styles.JoinUsLeft}>
                        <div className={styles.joinus1}>
                            <img src="\images\joinus1.png" />
                            <h3>Expert Team</h3>
                            <p>Collaborate with certified Google Cloud professionals and industry leaders.</p>
                        </div>
                        <div className={styles.joinus2}>
                            <img src="\images\joinus2.png" />
                            <h3>Innovative Projects</h3>
                            <p>Engage in transformative projects with Fortune 500 companies and leading global brands.</p>
                        </div>
                        <div className={styles.joinus3}>
                            <img src="\images\joinus3.png" />
                            <h3>Next-gen Technology</h3>
                            <p>Work with the latest in Google Cloud, Big Data, AI, and cloud optimization technologies.</p>
                        </div>
                    </div>
                    <div className={styles.JoinUsMiddle}>
                        <img src="\images\joinus.png" />
                    </div>
                    <div className={styles.JoinUsRight}>
                        <div className={styles.joinus4}>
                            <img src="\images\joinus4.png" />
                            <h3>Growth Opportunities</h3>
                            <p>Fast-track your career with clear growth paths, mentorship, and professional development.</p>
                        </div>
                        <div className={styles.joinus5}>
                            <img src="\images\joinus5.png" />
                            <h3>Dynamic Work Environment</h3>
                            <p>Thrive in a collaborative, supportive, and flexible workplace that values creativity.</p>
                        </div>
                        <div className={styles.joinus6}>
                            <img src="\images\joinus6.png" />
                            <h3>Competitive Benefits</h3>
                            <p>Enjoy attractive compensation, comprehensive health benefits, and performance-based incentives.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className={styles.shareDetails}>
                <div className={styles.shareDetailsHead}>
                    <h1>Share Your Details</h1>
                    <div className={styles.shareDetailsHeadp}><p>Our HR team will reach you out when there’s any opening that matches your profile</p></div>
                </div>
                <div className={styles.contactForm}>
                    <form className={styles.formShareDetails} onSubmit={handleSubmit}>
                        <div className={styles.contact1}>
                            <div>
                                <label>Full Name</label>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    name='fullName'
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    required />
                            </div>
                            <div className={styles.email}>
                                <label>Email ID</label>
                                <input
                                    type="email"
                                    placeholder="abc@mail.com"
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className={styles.contact2}>
                            <div>
                                <label>Contact Number</label>
                                <input
                                    type="number"
                                    placeholder="+91 90000 90000"
                                    name='contactNumber'
                                    value={formData.contactNumber}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className={styles.linUrl}>
                                <label>LinkedIn URL</label>
                                <input type="url"
                                    placeholder="Paste the Link"
                                    name='linkedIn'
                                    value={formData.linkedIn}
                                    onChange={handleChange}
                                    required />
                            </div>
                        </div>
                        <div className={styles.expertise}>
                            <label>Select Your Expertise</label>
                            <div className={styles.expertiseb} >
                                {['Google Cloud', 'Go', 'Python', 'React'].map(expertise => (
                                    <button
                                        type='button'
                                        key={expertise}
                                        onClick={() => handleExpertiseClick(expertise)}
                                        className={formData.expertise === expertise ? styles.activeExpertise : ""}
                                    >
                                        {expertise}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className={styles.locationRadioHead}>
                            <label>Are you ready to work from Hyderabad office location?</label>
                            <div className={styles.locationRadio}>
                                <input
                                    type="radio"
                                    id="yes"
                                    name="wfh"
                                    value="Yes"
                                    checked={formData.wfh === 'Yes'}
                                    onChange={handleLocationChange}
                                />
                                <label htmlFor="Yes">Yes</label>
                                <input
                                    type="radio"
                                    id="no"
                                    name="wfh"
                                    value="No"
                                    checked={formData.wfh === 'No'}
                                    onChange={handleLocationChange}
                                />
                                <label htmlFor="No">No</label>
                            </div>
                        </div>
                        <div className={styles.uploadFile}>
                            <label>Upload your CV or Portfolio (less than 1MB .pdf and .doc only)*</label>
                            <div className={styles.inputBtn}>
                                <input type="file" ref={fileInputRef} placeholder="No File Chosen" style={{ display: 'none' }} onChange={handleFileChange}/>
                                <button type="button" onClick={handleButtonClick} >Choose File</button>
                                {fileName}
                            </div>
                        </div>
                        <div className={styles.textMessage}>
                            <label>Message</label>
                            <textarea
                                placeholder="Your message..."
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <button type="submit" className={styles.formBtn}>
                            <span className={styles.apply}>Apply</span>
                            <span className={styles.arrow}><img src={rightArrow} alt="applyarrowimg" /></span>
                        </button>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default Careers;