import HeroComponent from "./components/Hero_comp";
import Slider from "./components/Slider";
import Inshorts from "./components/Inshorts_section";
import Cloud_Services from "./components/Cloud_section";
import Grid_view from "./components/GridView";
import Upcoming from "./components/Upcoming";
import Card from "./components/Case_studies";
import BlogPosts from "./components/Blogposts";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

import React, { useState, useEffect } from "react";

function App() {
  const useIsMobile = () => { 
		const [width, setWidth] = useState(0); 
		const handleWindowSizeChange = () => { 
			setWidth(window.innerWidth); 
		}; 
	  
		useEffect(() => { 
			handleWindowSizeChange(); 
			
			window.addEventListener("resize",  
				handleWindowSizeChange); 
	   
			return () => { 
				window.removeEventListener( 
					"resize", handleWindowSizeChange); 
			}; 
		}, []); 
	  
		return width <= 500; 
	}; 

  const isMobile = useIsMobile();
  return (
    <div> 
    {isMobile ? <Mobile /> : <Desktop />} 
</div> 
  );
}

function Mobile(){
  return(
    <main >
  <>
  <HeroComponent />
  <Slider />
  <Cloud_Services />
  <Inshorts />
  <Grid_view/>
  <Upcoming/>
  {/* <Card/> */}
  <BlogPosts/>
  </>
  </main> 

  )
}

function Desktop(){
  return(
    <main>
  <>
  <HeroComponent />
  <Slider />
  <Inshorts />
  <Cloud_Services />
  <Grid_view/>
  {/* <Upcoming/> */}
  {/* <Card/> */}
  {/* <BlogPosts/> */}
  </>
  </main> 
  )
}

 
export default App;
