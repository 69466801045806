import styles from "../styles/cloudServices.module.css";
import { useEffect, useState } from "react";


export default function Cloud_Services() {
  const useIsMobile = () => {
    const [width, setWidth] = useState(0);
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    useEffect(() => {
      handleWindowSizeChange();

      window.addEventListener("resize", handleWindowSizeChange);

      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }, []);

    return width <= 475;
  };

  const isMobile = useIsMobile();

  return <div>{isMobile ? <Mobile /> : <Desktop />}</div>;
}

function Mobile() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isPlus, setIsPlus] = useState(true);

  const toggleAccordion = (index : any) => {
    setActiveIndex(index === activeIndex ? null : index);
    setIsPlus(!isPlus);
  };

  const accordionData = [
    {
      title: `Migration `,
      content: (
        <>
          <div className={styles.text_content}>
            <div className={`${styles.migrate_text} ${styles.text}`}>
              <div className={styles.title}>Migration </div>
              <p className={styles.desc}>
                Advanced Tools for Unified Control, Optimization, and Real-Time
                Insights across Your Cloud Environment
              </p>
              <div className={styles.table_content}>
                <div className={styles.cell}>
                  <img src="/images/monitor.png" alt="Monitoring" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Assesment and Planning
                    </h2>
                    <p className={styles.sub_desc}>
                      Workload Analysis • Migration Readiness Assessment • TCO Analysis •
                      Custom Migration Roadmaps
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/debug.png" alt="Debugging" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Application Migration
                    </h2>
                    <p className={styles.sub_desc}>
                      Lift and Shit • Replatforming • Refactoring •
                      Application Modernization
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/resource.png" alt="Resource" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>Data Migration</h2>
                    <p className={styles.sub_desc}>
                      Database Migration Service • Storage Transfer Service •
                      Big Data Transfer • Streaming Data Transfer
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/automate.png" alt="Automation" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Hybrid and Multi-Cloud Integration
                    </h2>
                    <p className={styles.sub_desc}>
                      Hybrid Cloud Solutions • Multi-Cloud Management • Cloud Interconnect Setup •
                      Identity and Access Management Integration
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title:  `Management `,
      content: (
        <>
          <div className={styles.text_content}>
            <div className={`${styles.migrate_text} ${styles.text}`}>
              <div className={styles.title}>Management</div>
              <p className={styles.desc}>
                Advanced Tools for Unified Control, Optimization, and Real-Time
                Insights across Your Cloud Environment
              </p>
              <div className={styles.table_content}>
                <div className={styles.cell}>
                  <img src="/images/monitor.png" alt="Monitoring" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Monitoring and Logging
                    </h2>
                    <p className={styles.sub_desc}>
                      Cloud Monitoring • Cloud Logging • Custom Metrics •
                      Alerting and Notifications
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/debug.png" alt="Debugging" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Debugging and Profiling
                    </h2>
                    <p className={styles.sub_desc}>
                      Cloud Debugger • Cloud Profiler • Error Reporting •
                      Performance Tracing
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/resource.png" alt="Resource" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>Resource Management</h2>
                    <p className={styles.sub_desc}>
                      Idle Resource Management • Instance Right-Sizing •
                      Autoscaling Policies • Resource Utilization Reports
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/automate.png" alt="Automation" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Automation and Orchestration
                    </h2>
                    <p className={styles.sub_desc}>
                      Cloud Scheduler • Cloud Tasks • Deployment Manager •
                      Managed Instance Groups
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title:  `Optimization `,
      content: (
        <>
          <div className={styles.text_content}>
            <div className={`${styles.migrate_text} ${styles.text}`}>
              <div className={styles.title}>Optimization</div>
              <p className={styles.desc}>
                Advanced Tools for Unified Control, Optimization, and Real-Time
                Insights across Your Cloud Environment
              </p>
              <div className={styles.table_content}>
                <div className={styles.cell}>
                  <img src="/images/monitor.png" alt="Monitoring" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Budgeting and Forecasting
                    </h2>
                    <p className={styles.sub_desc}>
                      Budget Alerts • Cost Forecasting • Billing Reports •
                      Cost Allocation Tags
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/debug.png" alt="Debugging" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Resource Optimization
                    </h2>
                    <p className={styles.sub_desc}>
                      Idle Resource Management • Instant Right-Sizing • Autoscaling Policies •
                      Resource Utilization Reports
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/resource.png" alt="Resource" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>Spend Analysis</h2>
                    <p className={styles.sub_desc}>
                      Billing Export to BigQuery • Cost Analysis Dashboards •
                      Spend Trends and Patterns • ROI Calculations
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/automate.png" alt="Automation" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Recommendations Engine
                    </h2>
                    <p className={styles.sub_desc}>
                      Compute Optimizer • Storage Recommendations • Network Optimization •
                      AI-Driven Cost Insights
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: `Big Data `,
      content: (
        <>
          <div className={styles.text_content}>
            <div className={`${styles.migrate_text} ${styles.text}`}>
              <div className={styles.title}>Big Data</div>
              <p className={styles.desc}>
                Advanced Tools for Unified Control, Optimization, and Real-Time
                Insights across Your Cloud Environment
              </p>
              <div className={styles.table_content}>
                <div className={styles.cell}>
                  <img src="/images/monitor.png" alt="Monitoring" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Data Warehousing
                    </h2>
                    <p className={styles.sub_desc}>
                      BigQuery Setup • Data Partitioning • Query Optimization •
                      Data Encryption
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/debug.png" alt="Debugging" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      ETL and Data Integration
                    </h2>
                    <p className={styles.sub_desc}>
                      Dataflow Pipelines • Cloud Data Fusion • Dataprep for Data Cleaning •
                      Real-Time Data Ingestion
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/resource.png" alt="Resource" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>Stream Processing</h2>
                    <p className={styles.sub_desc}>
                      Apache Beam on Dataflow • Pub/Sub Messaging •
                      Event-Driven Architectures • Real-Time Analytics
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/automate.png" alt="Automation" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Data Governance
                    </h2>
                    <p className={styles.sub_desc}>
                      Data Cataloging • Metadata Management • Data Lineage Tracking •
                      Data Quality Monitoring
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: `Data Science ` ,
      content: (
        <>
          <div className={styles.text_content}>
            <div className={`${styles.migrate_text} ${styles.text}`}>
              <div className={styles.title}>Data Science</div>
              <p className={styles.desc}>
                Advanced Tools for Unified Control, Optimization, and Real-Time
                Insights across Your Cloud Environment
              </p>
              <div className={styles.table_content}>
                <div className={styles.cell}>
                  <img src="/images/monitor.png" alt="Monitoring" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Model Development
                    </h2>
                    <p className={styles.sub_desc}>
                      Verter AI Workbench • Tensorflow Model Training • AutoML Custom Models •
                      Pre-trained Model Fine-Tuning
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/debug.png" alt="Debugging" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Model Deployment
                    </h2>
                    <p className={styles.sub_desc}>
                      Vertex AI Model Serving • Custom APIs for ML Models • Real-Time Interface •
                      A/B Testing for Models
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/resource.png" alt="Resource" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>Data Preparation</h2>
                    <p className={styles.sub_desc}>
                      Data Labeling Services • Feature Engineering •
                      Dataset Versioning • Data Augmentation
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/automate.png" alt="Automation" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Model Monitoring and Maintenance
                    </h2>
                    <p className={styles.sub_desc}>
                      Continuous Model Evaluation • Drift Detection • Model Retaining Pipelines •
                      Performance Dashboards
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={styles.cloud_section}>
      <div className={styles.headingWrapper}>
        <p className={styles.short_heading}>We Are Specialized In </p>
        <div className={styles.line}></div>
      </div>
      <div className={styles.main_title}>Cloud Services</div>
      <div className="accordion-container">
        {accordionData.map((item, index) => (
          <div key={index} className="accordion-item">
            <button
              className="accordion-title"
              onClick={() => toggleAccordion(index)}
            >
              {item.title} 
              {isPlus ? '+' : '-'}
            </button>
            {activeIndex === index && (
              <div className="accordion-content">{item.content}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function Desktop() {
  return (
    <div className={styles.cloud_section}>
      <div className={styles.headingWrapper}>
        <p className={styles.short_heading}>We Are Specialized In </p>
        <div className={styles.line}></div>
      </div>
      <div className={styles.main_title}>Cloud Services</div>

      <div className={styles.container}>
        <div className={styles.content}>
          <input
            type="radio"
            name="indicator"
            id="migration"
            className={styles.migrate}
          />
          <input
            type="radio"
            name="indicator"
            id="management"
            className={styles.manage}
          />
          <input
            type="radio"
            name="indicator"
            id="optimization"
            className={styles.optimize}
          />
          <input
            type="radio"
            name="indicator"
            id="big_data"
            className={styles.big_dat}
          />
          <input
            type="radio"
            name="indicator"
            id="data_science"
            className={styles.dataScience}
          />

          <div className={styles.list}>
            <label htmlFor="migration" className={styles.migration}>
              <span className={styles.topic}>Migration</span>
            </label>
            <label htmlFor="management" className={styles.management}>
              <span className={styles.topic}>Management</span>
            </label>
            <label htmlFor="optimization" className={styles.optimization}>
              <span className={styles.topic}>Optimization</span>
            </label>
            <label htmlFor="big_data" className={styles.big_data}>
              <span className={styles.topic}>Big Data</span>
            </label>
            <label htmlFor="data_science" className={styles.data_science}>
              <span className={styles.topic}>Data Science</span>
            </label>
            <div className={styles.indicator}></div>
            <div className={styles.white_top}></div>
            <div className={styles.white_bottom}></div>
          </div>
          <div className={styles.text_content}>
            <div className={`${styles.migrate_text} ${styles.text}`}>
              <div className={styles.title}>
              Ensuring Data Integrity
              </div>
              <p className={styles.desc}>
              Smooth transitions to the cloud with minimal downtime and guaranteed data integrity
              </p>
              <div className={styles.table_content}>
                <div className={styles.cell}>
                  <img src="/images/monitor.png" alt="Monitoring" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Assessment and Planning
                    </h2>
                    <p className={styles.sub_desc}>
                      Workload Analysis • Migration Readiness Assessment • TCO Analysis •
                      Custom Migration Roadmaps
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/debug.png" alt="Debugging" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Application Migration
                    </h2>
                    <p className={styles.sub_desc}>
                      Lift and Shift • Replatforming • Refactoring •
                      Application Modernization
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/resource.png" alt="Resource" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>Data Migration</h2>
                    <p className={styles.sub_desc}>
                      Database Migration Service • Storage Transfer Service •
                      Big Data Transfer • Streaming Data Transfer
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/automate.png" alt="Automation" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Hybrid and Multi-Cloud Integration
                    </h2>
                    <p className={styles.sub_desc}>
                      Hybrid Cloud Solutions • Multi-Cloud Management • Cloud Interconnect Setup •
                      Identity and Access Management Integration
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.manage_text} ${styles.text}`}>
              <div className={styles.title}>
                End-to-end Cloud Operations
              </div>
              <p className={styles.desc}>
                Advanced Tools for Unified Control, Optimization, and Real-Time
                Insights across Your Cloud Environment
              </p>
              <div className={styles.table_content}>
                <div className={styles.cell}>
                  <img src="/images/monitor.png" alt="Monitoring" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Monitoring and Logging
                    </h2>
                    <p className={styles.sub_desc}>
                      Cloud Monitoring • Cloud Logging • Custom Metrics •
                      Alerting and Notifications
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/debug.png" alt="Debugging" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Debugging and Profiling
                    </h2>
                    <p className={styles.sub_desc}>
                      Cloud Debugger • Cloud Profiler • Error Reporting •
                      Performance Tracing
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/resource.png" alt="Resource" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>Resource Management</h2>
                    <p className={styles.sub_desc}>
                      Idle Resource Management • Instance Right-Sizing •
                      Autoscaling Policies • Resource Utilization Reports
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/automate.png" alt="Automation" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Automation and Orchestration
                    </h2>
                    <p className={styles.sub_desc}>
                      Cloud Scheduler • Cloud Tasks • Deployment Manager •
                      Managed Instance Groups
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.optimize_text} ${styles.text}`}>
              <div className={styles.title}>
              Performance and Cost Efficiency
              </div>
              <p className={styles.desc}>
              We help you achieve optimal cloud operations and significant cost savings
              </p>
              <div className={styles.table_content}>
                <div className={styles.cell}>
                  <img src="/images/monitor.png" alt="Monitoring" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                        Budgeting and forecasting
                    </h2>
                    <p className={styles.sub_desc}>
                      Budget Alerts • Cost Forecasting • Billing Reports •
                      Cost Allocation Tags
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/debug.png" alt="Debugging" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Resource Optimization
                    </h2>
                    <p className={styles.sub_desc}>
                      Idle Resource Management • Instance Right-Sizing • Autoscaling Policies •
                      Resouce Utilization Reports
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/resource.png" alt="Resource" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>Spend Analysis</h2>
                    <p className={styles.sub_desc}>
                      Billing Export to BigQuery • Cost Analysis Dashboards •
                      Spend Trends and Patterns • ROI Calculation
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/automate.png" alt="Automation" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Recommendations Engine
                    </h2>
                    <p className={styles.sub_desc}>
                      Compute Optimizer • Storage Recommendations • Network Optimization •
                      AI-Driven Cost Insights
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.bigData_text} ${styles.text}`}>
              <div className={styles.title}>
              Unlock The Full Potential Of Data
              </div>
              <p className={styles.desc}>
              Scalable architectures for actionable data insights and strategic growth
              </p>
              <div className={styles.table_content}>
                <div className={styles.cell}>
                  <img src="/images/monitor.png" alt="Monitoring" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Data Warehousing
                    </h2>
                    <p className={styles.sub_desc}>
                      BigQuery Setup • Data Partitioning • Query Optimization •
                      Data Encryption
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/debug.png" alt="Debugging" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      ETL and Data Integration
                    </h2>
                    <p className={styles.sub_desc}>
                      Dataflow Pipelines • Cloud Data Fusion • Dataprep for Data Cleaning •
                      Real-Time Data Ingestion
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/resource.png" alt="Resource" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>Stream Processing</h2>
                    <p className={styles.sub_desc}>
                      Apache Beam on Dataflow • Pub/Sub Messaging •
                      Event-Driven Architectures • Real-Time Analytics
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/automate.png" alt="Automation" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Data Governance
                    </h2>
                    <p className={styles.sub_desc}>
                      Data Cataloging • Metadata Management • Data Lineage Tracking •
                      Data Quality Monitoring
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.dataSci_text} ${styles.text}`}>
              <div className={styles.title}>
              AI, ML & Analytics Engineering
              </div>
              <p className={styles.desc}>
              Integrating AI in your solutions to transform user experience
              </p>
              <div className={styles.table_content}>
                <div className={styles.cell}>
                  <img src="/images/monitor.png" alt="Monitoring" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Model Development
                    </h2>
                    <p className={styles.sub_desc}>
                      Vertex AI Workbench • TensorFlow Model Training • AutoML Custom Models •
                      Pre-trained Model Fine-Tuning
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/debug.png" alt="Debugging" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Model Deployment
                    </h2>
                    <p className={styles.sub_desc}>
                      Vertex AI Model Serving • Custom APIs for ML Models • Real-Time Inference •
                      A/B Testing for Models
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/resource.png" alt="Resource" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>Data Preparation</h2>
                    <p className={styles.sub_desc}>
                      Data Labeling Services • Feature Engineering •
                      Dataset Versioning • Data Augmentation
                    </p>
                  </div>
                </div>
                <div className={styles.cell}>
                  <img src="/images/automate.png" alt="Automation" />
                  <div className={styles.cell_content}>
                    <h2 className={styles.sub_heading}>
                      Model Monitoring and Maintenance
                    </h2>
                    <p className={styles.sub_desc}>
                      • Continuous Model Evaluation 
                      • Drift Detection 
                      • Model Retaining Pipelines 
                      • Performance Dashboards
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styles.logo}><img src="/images/white_logo.png" alt="White Company Logo" /></div> */}
        </div>
      </div>
    </div>
  );
}
