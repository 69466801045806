import styles from "../styles/migration.module.css"
import Slider from '../components/Slider';
import Card from '../components/Case_studies';
import getIntouchBtn from "../assets/images/getInTouchBtn.svg"

const Migration = () => {
    return (
        <>
         <div className={styles.container}>
            <div className={styles.left_div}>
                <div className={styles.m_logo}><img src="/images/m_logo.png" alt="Company logo"/></div>
                <h1 className={styles.heading}>Efficient Cloud Migration for Modern Businesses</h1>
                <p className={styles.desc}>Move to the Google Cloud effortlessly with HYPR4. Our comprehensive migration solutions ensure a secure, scalable, and cost-effective transition for your business.</p>
                {/* <button className={styles.cont_btn}>Get In Touch</button> */}
                <div className={styles.cont_btn}><img src={getIntouchBtn} alt="touch btn"/></div>
            </div>

            <div className={styles.right_div}>
                <img src="\images\migration_hero.png" className={styles.right_img} alt="Hero Image"/>
            </div>
        </div>
        <Slider />
        <>
        <div className={styles.headingWrapper}>
				<p className={styles.short_heading}>Cloud Migration Benefits</p>
				<div className={styles.line}></div>
			</div>
			<div className={styles.inshorts_left}>
				<h1 className={styles.inshorts_head}>The Numbers Talk</h1>
		</div>
        <div className={styles.NumbersContainer}>
            <div className={styles.NumberBlock1}>
                <h1>40%</h1>
                <p>Professionals use automated policies to reduce wasted spend</p>
                <h3>Cost Savings</h3>
            </div>
            <div className={styles.NumberBlock2}>
                <h1>85%</h1>
                <p>Organizations deploy applications on two or more IaaS providers - attesting the era of multicloud</p>
                <h3>Increased Scalability</h3>
            </div>
            <div className={styles.NumberBlock3}>
                <h1>14%</h1>
                <p>Cloud users more likely to exceed performance goals than non-users</p>
                <h3>Performance Boost</h3>
            </div>
            <div className={styles.NumberBlock4}>
                <h1>40%</h1>
                <p>enterprises prioritize strong data protection and control capabilities in the cloud</p>
                <h3>Improved Security</h3>
            </div>
        </div>
        
        <div className={styles.ourUps}>
            <div className={styles.headingWrapper}>
				<p className={styles.short_heading1}>Why Hypr4</p>
				<div className={styles.line}></div>
			</div>
			<div className={styles.inshorts_left}>
				<h1 className={styles.inshorts_head}>Our USPs</h1>
            </div>
            <div className={styles.gridItems}>
                <div className={styles.gridItem1}>
                    <h3>Certified Cloud Experts</h3>
                    <p>Advanced Google Cloud certifications and a track record of successful Fortune 500 migrations ensure top-tier expertise.</p>
                </div>
                <div className={styles.gridItem2}>
                    <h3>Tailored Strategies</h3>
                    <p>Custom cloud architectures and precise migration roadmaps optimize performance and cost-efficiency.</p>
                </div>
                <div className={styles.gridItem3}>
                    <h3>Advanced Data Handling</h3>
                    <p>Secure, real-time data transfers and seamless integration of Big Data and AI capabilities enhance analytics.</p>
                </div>
                <div className={styles.gridItem4}>
                    <h3>Robust Security</h3>
                    <p>Comprehensive security protocols and adherence to industry standards like GDPR, HIPAA, and SOC 2 ensure data protection.</p>
                </div>
                <div className={styles.gridItem5}>
                    <h3>Continuous Optimization</h3>
                    <p>24/7 monitoring and proactive issue resolution with continuous optimization for peak performance.</p>
                </div>
                <div className={styles.gridItem6}>
                    <h3>Strategic Partnerships</h3>
                    <p>Direct access to Google Cloud resources and a client-centric approach for personalized solutions and ongoing engagement.</p>
                </div>
            </div>
            <div className={styles.gridBtn}>
            {/* <button className={styles.cont_btn}>Get In Touch</button> */}
            <div className={styles.cont_btn}><img src={getIntouchBtn} /></div>
            </div>
		</div>

        <div>
        <div className={styles.headingWrapper}>
				<p className={styles.short_heading}>Key GCP Services Involved</p>
				<div className={styles.line}></div>
			</div>
			<div className={styles.inshorts_left}>
				<h1 className={styles.migration_head}>Migration Technologies</h1>
            </div>
            <div className={styles.migrationContainer}>
                <div className={styles.migrationTech_left}>
                    <div className={styles.tech_points}>
                        <h3>Google Cloud Platform</h3>
                        <ul>
                            <li>Google Compute Engine</li>
                            <li>Google Kubernetes Engine</li>
                            <li>Google Cloud Storage</li>
                            <li>BigQuery</li>
                        </ul> 
                    </div>

                    <div className={styles.tech_points}>
                        <h3>Migration Tools</h3>
                        <ul>
                            <li>Google Cloud Migrate for Compute Engine</li>
                            <li>Transfer Appliance</li>
                        </ul> 
                    </div>

                    <div className={styles.tech_points}>
                        <h3>Database Solutions</h3>
                        <ul>
                            <li>Cloud SQL</li>
                            <li>Cloud Spanner</li>
                            <li>Datastore</li>
                        </ul> 
                    </div>

                    <div className={styles.tech_points}>
                        <h3>Security Tools</h3>
                        <ul>
                            <li>Cloud IAM</li>
                            <li>Cloud Security Scanner</li>
                            <li>Cloud Key Management</li>
                        </ul> 
                    </div>
                </div>
                <div className={styles.migration_right}>
                    <img src='\images\migrationTechRightImg.png' alt='Right Migration Tech' />
                </div>
            </div>
        </div>
        </>
        {/* <Card /> */}
        </>
    );
};

export default Migration;