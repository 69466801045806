import styles from "../styles/contact_us.module.css";
import getInTouchBtn from "../assets/images/getInTouchBtn.svg"

export default function Contact(){
    return(
        <div className={styles.contact_container}>
            <div className={styles.content_side}>
                <h1>Discover How We Can Help You Reduce Your Cloud Spend</h1>
                <p>Cut costs intelligently with our cloud optimization strategies</p>
            </div>
            <div className={styles.textbox_side}>
                <p>Enter Your Best Email</p>
                <input type="text"/>
                <div className={styles.contact_btn}><img src={getInTouchBtn} /></div>
                {/* <button className={styles.contact_btn}>Get In Touch</button> */}
                <div className={styles.back_img}><img src="/images/contact_back.png" alt="background_img" /></div>
            </div>
        </div>
    )
}