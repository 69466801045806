import styles from '../styles/contact_page.module.css';
import getInTouchBtn from "../assets/images/getInTouchBtn.svg";
import indIcon from '../assets/images/IndiaIcon.svg';
import { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { format } from 'path';
import rightArrow from '../assets/images/Arrow 17.svg';
import singleArrow from '../assets/images/single arrow.svg';
import groupArrow from '../assets/images/Group arrows.svg';
import cloudImage from '../assets/images/cloudimg.svg';
import groupLeaf from '../assets/images/Group leaf.svg';
import worldIcon from '../assets/images/freepik--world-icon--inject-2.svg';



const ContactPage: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNumber: '',
        message: '',
    });
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileName, setFileName] = useState<string | null>("No File choosen");

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the file input click
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            console.log('Selected file:', files[0]); // Handle the file as needed
            setFileName(files[0]?.name)
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const emailData = {
            to_name: 'Team',
            from_name: formData.name,
            name: formData.name,
            email: formData.email,
            contactNumber: formData.contactNumber,
            message: formData.message,
        };
        // Send email using EmailJS
        emailjs.send('service_ry6v48b', 'template_rtt2iim', emailData, 's8HlBBhcGY0-qKPKf')
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
                // Optionally, reset the form
                setFormData({
                    name: '',
                    email: '',
                    contactNumber: '',
                    message: '',
                });
                setFileName("No File chosen");
            }, (err) => {
                console.error('Failed to send email. Error: ', err);
            });
    };
    return (
        <>
            <div className={styles.container}>
                <div className={styles.left_div}>
                    <div className={styles.m_logo}><img src="/images/m_logo.png" alt="Company logo" /></div>
                    <h1 className={styles.heading}>Connect with Our Google Cloud Experts</h1>
                    <p className={styles.desc}>Explore how we can support your cloud needs. Our Google Cloud experts are here to provide the solutions and guidance you require.</p>
                    {/* <button className={styles.cont_btn}>Get In Touch</button> */}
                    <div className={styles.cont_btn}><img src={getInTouchBtn} /></div>
                </div>

                <div className={styles.right_div}>
                    <img src="\images\contact_hero.png" className={styles.right_img} alt="Hero Image" />
                </div>
            </div>

            <div >
                <div className={styles.headingWrapper}>
                    <p className={styles.short_heading}>Fill The Form To Contact Us</p>
                    <div className={styles.line}></div>
                </div>
                <div className={styles.offAdd}>
                    <div className={styles.address}>
                        <h1>Unlock  HYPR Cloud Possibilities!</h1>
                        <p className={styles.oAdd}>Discover how HYPR4’s expertise in Google Cloud drives business success and innovation.</p>
                    </div>
                    <div className={styles.contactForm}>
                        <span><img className={styles.singleArrow} src={singleArrow} alt='singleArrow' /></span>
                        <span><img className={styles.groupArrow} src={groupArrow} alt='groupArrow' /></span>
                        <span><img className={styles.cloudImage} src={cloudImage} alt='cloudImage' /></span>
                        <span><img className={styles.groupLeaf} src={groupLeaf} alt='groupLeaf' /></span>
                        <span><img className={styles.worldIcon} src={worldIcon} alt='worldIcon' /></span>
                        <span className={styles.worldiconSpan}></span>
                        <span className={styles.groupLeafSpan}></span>
                        <form onSubmit={handleSubmit}>
                            <h1 className={styles.diveIn}>Dive In</h1>
                            <div className={styles.formFields}>
                                <label>Name</label>
                                <input
                                    type='text'
                                    placeholder='Name'
                                    name='name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    required />
                            </div>
                            <div className={styles.formFields}>
                                <label>Email ID</label>
                                <input
                                    type='email'
                                    placeholder='User@gmail.com'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required />
                            </div>
                            <div className={styles.formFields}>
                                <div>
                                    <label>Contact No</label>
                                    <div>
                                        <div>
                                            <img src={indIcon} alt='phIcon' />
                                            <span>+91</span>
                                        </div>
                                        <div>
                                            <input
                                                type='number'
                                                placeholder='99999-99999'
                                                name='contactNumber'
                                                value={formData.contactNumber}
                                                onChange={handleChange}
                                                required />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className={styles.formFields}>
                                <label>Message</label>
                                <textarea
                                    placeholder='Your message...'
                                    name='message'
                                    value={formData.message}
                                    onChange={handleChange}
                                > sadasdsad</textarea>
                            </div>
                            <button type="submit" className={styles.formBtn}>
                                <span className={styles.apply}>Apply</span>
                                <span className={styles.arrow}><img src={rightArrow} alt="applyarrowimg" /></span>
                            </button>
                        </form>
                    </div>

                </div>
            </div>

            <div >
                <div className={styles.headingWrapper}>
                    <p className={styles.short_heading}>Corporate Office Contact</p>
                    <div className={styles.line}></div>
                </div>
                <div className={styles.offAdd}>
                    <div className={styles.OfficeAddImg}><img src='\images\contactOffice.png' /></div>

                    <div className={styles.address}>
                        <h1>Headquarters</h1>
                        <div className={styles.pinAddress}>
                            <img src='\images\pinImg.png' />
                            <p><strong>India</strong></p>
                        </div>

                        <p className={styles.oAdd}>1st Floor, Survey no. 43 to 48, Plot. 83, Kavuri Hills, Jubilee Hills, Hyderabad, Telangana 500033</p>
                        <p><strong>hello@hypr4.co</strong></p>
                        <div className={styles.pinAddress}>
                            <img src='\images\call.png' />
                            <p><strong>99000 99900</strong></p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactPage;