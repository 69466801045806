import styles from "../styles/slider.module.css";
import slider1 from "../assets/images/sliderEDF.svg";
import slider2 from "../assets/images/sliderMachinmax.svg";
import slider3 from "../assets/images/sliderGC.svg";
import slider4 from "../assets/images/sliderMongoDB.svg";
import slider5 from "../assets/images/sliderAUMIRAH.svg";
import slider6 from "../assets/images/sliderIITH.svg"
// import slider1 from "../assets/images/slider1.png"
// import ShortHeadings from "./ShortHeadings";


const Slider = () => {
    return(
        <div className={styles.container}>
            {/* <ShortHeadings heading="Brands Who Trust Us"/> */}
            <div className={styles.headingWrapper}>
            <p className={styles.short_heading}>Brands Who Trust Us </p>
            <div className={styles.line}></div>
            </div>
            <div className={styles.slider}>
                <div className={styles.slide_track}>
                    <div className={styles.slide}>
                        <img src={slider1} alt="Slider1" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider2} alt="Slider2" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider3} alt="Slider3" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider4} alt="Slider4" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider5} alt="Slider5" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider6} alt="Slider6" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider1} alt="Slider1" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider2} alt="Slider2" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider3} alt="Slider3" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider4} alt="Slider4" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider5} alt="Slider5" />
                    </div>
                    <div className={styles.slide}>
                        <img src={slider6} alt="Slider6" />
                    </div>
                </div>
            </div>
            <div className={styles.up_arrow}>
                <img src="/images/slider_arrow.png" alt="Slider Arrow" />
            </div>
        </div>
    )
}

export default Slider;