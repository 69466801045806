import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './pages/About';
import Migration from './pages/Migration';
import ContactPage from './pages/ContactPage';

import reportWebVitals from './reportWebVitals';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Careers from './pages/CarrersPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
   <BrowserRouter>
    <Navbar />
    <Routes>
    <Route path="/" element={<App />} />
    {/* <Route path='/navmenu' element={<NavMenu />} /> */}
    <Route path="/about" element={<About />} />
    <Route path="/migration" element={<Migration />} />
    <Route path="/contact" element={<ContactPage />} />
    <Route path="/careers" element={<Careers />} />
    </Routes>
    <Contact />
    <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
