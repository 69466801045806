import styles from "../styles/blog_cards.module.css";



const BlogPosts = () => {
    return (
        <div className={styles.blogs_view}>
        <div className={styles.headingWrapper}>
                <p className={styles.short_heading}>Blog Posts</p>
                <div className={styles.line}></div>
            </div>

        <div className={styles.main_title}>
            <h1>We Share</h1>
            <button className={styles.view_btn}><div className={styles.btn_link}><h2>View More</h2><img src="/images/pink_arrow.png" alt="Pink Arrow" /></div></button>
        </div>        
    <div className={styles.blogs_container}>
        <div className={styles.card}>
            <div className={styles.img_div}>
                <img src="/images/blogs1.png" alt="Grey Arrow" />
                <div className={styles.w_logo}><img src="/images/white_logo.png" alt="White Logo" /></div>
            </div>
            <div className={styles.content}>
                <p className={styles.hashtags}>#BigData #AI</p>
                <h2 className={styles.heading}>Google is a Leader in The Forrester Wave™</h2>
                <a href="#"><img src="/images/pink_arrow.png" alt="Pink Arrow" /></a>
            </div>
        </div>

        <div className={styles.card}>
            <div className={styles.img_div}>
                <img src="/images/blogs2.png" alt="Grey Arrow" />
                <div className={styles.w_logo}><img src="/images/white_logo.png" alt="White Logo" /></div>
            </div>
            <div className={styles.content}>
                <p className={styles.hashtags}>#CloudEngineering #GCP</p>
                <h2 className={styles.heading}>5 more myths about platform engineering: how it’s built, what it...</h2>
                <a href="#"><img src="/images/pink_arrow.png" alt="Pink Arrow" /></a>
            </div>
        </div>

        <div className={styles.card}>
            <div className={styles.img_div}>
                <img src="/images/blogs3.png" alt="Grey Arrow" />
                <div className={styles.w_logo}><img src="/images/white_logo.png" alt="White Logo" /></div>
            </div>
            <div className={styles.content}>
                <p className={styles.hashtags}>#Cloud Management</p>
                <h2 className={styles.heading}>The overwhelmed person’s guide to Google Cloud: week of May 30</h2>
                <a href="#"><img src="/images/pink_arrow.png" alt="Pink Arrow" /></a>
            </div>
        </div>
    </div>
</div>
    )
}

export default BlogPosts;