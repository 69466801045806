import React, { useEffect, useRef, useState } from "react";
import styles from "../styles/navbar.module.css";
import { useNavigate } from 'react-router-dom';
import Hypr4Name from '../assets/images/HYPR4.svg';
// import navOpen from "../assets/images/navOpen.svg";
import navOpen from '../assets/images/Group.svg';
import navClose from "../assets/images/navClose.svg";
import scheduleCall from "../assets/images/scheduleCall.svg"
import emailjs from 'emailjs-com';
import rightArrow from '../assets/images/Arrow 17.svg';



const Navbar: React.FC = () => {
	const [open, setOpen] = useState(false);
	const [subServices, setSubServices] = useState(false);
	const [subResources, setSubResources] = useState(false);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		contactNumber: "",
		fullName: "",
	})
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	}

	const handleSubmit = (e:React.FormEvent) => {
	e.preventDefault();

	const emailData = {
		to_name: "Team",
		from_name: formData.fullName,
		contactNumber:formData.contactNumber,
		fullName:formData.fullName,
	};

	// sending email
	emailjs.send('service_ry6v48b','template_e0jl34n',emailData,'s8HlBBhcGY0-qKPKf')
	.then((response) =>{
		console.log("menu email sent successfully",response.status,response.text);
		setFormData({
			contactNumber:"",
			fullName:"",
		});
	},(err)=>{
		console.error("Failed to send email for schedule a call.Error:",err);
	})

}

const aboutPage = () => {
	navigate('/about');
	setOpen(!open)
}

const migrationPage = () => {
	navigate('/migration')
	setOpen(!open)
}

const contactPage = () => {
	navigate('/contact')
	setOpen(!open)
}

const careersPage = () => {
	navigate('/careers')
	setOpen(!open)
}

useEffect(() => {
	document.body.style.overflowY = open ? 'hidden' : ''
	document.documentElement.style.overflowY = open ? 'hidden' : ''
}, [open])

return (
	<>
		<header className={styles.nav_menu}>
			<div id={styles.brand_name}>
				<a href="/">
					<img
						src={Hypr4Name} // "/images/comp_name.png"
						alt="Company Name"
					/>
				</a>
			</div>

			<div
				className={styles.menu_btn}
				onClick={() => {
					setOpen(!open);
					// document.body.style.overflowY = !open ? 'hidden':''
					// document.documentElement.style.overflowY = !open ? 'hidden':''
				}}
			>
				{(!open) ? <img src={navOpen} alt="Nav Logo" /> : <img src={navClose} className={styles.navCloseImg} alt="NavCloseLogo" />}

			</div>
		</header>
		{open ? (
			<div className={styles.menu_box}>
				{/* <img src="/images/leaf_nav.png" className={styles.leaf_nav} alt="leaves"/> */}
				{/* <div>
						<span><img src={Hypr4Name} /></span>
						<span></span>
					</div> */}
				<div className={styles.mobileContact}>
					<p>Need Help?</p>
					<h3>Lets Talk</h3>
					<div>
						<form className={styles.form} onSubmit={handleSubmit}>
							<div className={styles.input}>
								<label>Phone number
									<input
										type="number"
										placeholder=" +91 9700 5566 77"
										name="contactNumber"
										value={formData.contactNumber}
										onChange={handleChange}
										required />
								</label>
							</div>
							<div className={styles.input}>
								<label>Full Name
									<input 
									    type="text"
										placeholder="Name"
										name="fullName"
										value={formData.fullName}
										onChange={handleChange}
										required
									    />
								</label>
							</div>
							<button type="submit" className={styles.formBtn}>
                                <span className={styles.apply}>Apply</span>
                                <span className={styles.arrow}><img src={rightArrow} alt="applyarrowimg" /></span>
                            </button>
							{/* <button type="submit">Schedule a call</button> */}
							{/* <div className={styles.contbtn}><img src={scheduleCall} /></div> */}
						</form>
					</div>
				</div>
				<nav className={styles.navroute}>
					<ul className={styles.menu_list}>
						<li className={styles.menu_item} style={{ cursor: "pointer" }} onClick={() => aboutPage()}><h1>About</h1>
						</li>
						<>
							<li className={styles.sub_item}>
								About Us
							</li>
							<li className={styles.sub_item}>
								Why Hypr4
							</li>
							<li className={styles.sub_item}>
								The I4
							</li>
						</>
						<li className={styles.menu_item} onClick={() => migrationPage()} style={{ cursor: "pointer" }}><h1>Cloud Services</h1></li>
						<>
							<li className={styles.sub_item} > {/*onClick={() => setSubServices(!subServices)} */}
								Migration
							</li>
							<li className={styles.sub_item}>
								Management
							</li>
							<li className={styles.sub_item}>
								Optimization
							</li>
							<li className={styles.sub_item}>
								Big Data
							</li>
							<li className={styles.sub_item}>
								Data Science
							</li>
						</>
						<li className={styles.menu_item} style={{ cursor: "pointer" }} onClick={() => contactPage()}><h1>Contact</h1></li>
						<>
							<li className={styles.sub_item} onClick={() => migrationPage()}>
								Patner With Us
							</li>
							<li className={styles.sub_item}>
								Talk To Us
							</li>
						</>
						<li className={styles.menu_item} style={{ cursor: "pointer" }} onClick={() => careersPage()}><h1>Careers</h1></li>
						<>
							<li className={styles.sub_item} >
								Explore Opportunities
							</li>
							<li className={styles.sub_item}>
								Share your CV
							</li>
						</>
						<li className={styles.menu_item} onClick={() => setSubResources(!subResources)} style={{ cursor: "pointer" }}><h1>Resources</h1></li>
						<>
							<li className={styles.sub_item}>
								Blogs
							</li>
							<li className={styles.sub_item}>
								Case Studies
							</li>
						</>
					</ul>
				</nav>
				<div className={styles.social_icons}>
					<a href="https://www.linkedin.com/company/hypr4/posts/?feedView=all" className={styles.sicons}>
						<img
							src="/images/linkedin.png"
							alt="LinkedIn"
						/>
					</a>
					<a href="#" className={styles.sicons}>
						<img
							src="/images/youtube_icon.png"
							alt="Youtube"
						/>
					</a>
					{/* <a href="#" className={styles.sicons}>
							<img
								src="/images/instagram.png"
								alt="Instagram"
							/>
						</a> */}
					<a href="https://x.com/hypr4_io?t=X6qxS_1qVvxUNUHALuS4Vw&s=09" className={styles.sicons}>
						<img
							src="/images/twitter.png"
							alt="Twitter"
						/>
					</a>
				</div>
			</div>
		) : (
			""
		)}
	</>
);
}

export default Navbar;