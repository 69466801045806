import styles from "../styles/upcoming.module.css";
import joinListBtn from "../assets/images/joinTheWaitListBtn.svg"

export default function Upcoming() {
   
	return (
        <>
            <div className={styles.upcoming_container}>
            <div className={styles.headingWrapper}>
					<p className={styles.short_heading}>Our (Awesome) Solution</p>
					<div className={styles.line}></div>
				</div>

                <div className={styles.top_section}>

                    <div className={styles.left_section}>
                    <div className={styles.main_title}>Upcoming...</div>

                        <p>
                        <strong>Muxly</strong> is an AI-driven productivity platform for teams that deal with content and data every day!</p>
                        <p>It helps streamline content and data operations through AI Assistant to reduce manual efforts and improve the quality and delivery time.
                        </p>
                    </div>
                    <div className={styles.right_section}>
                       <div className={styles.mlogo}>
                       <img src="/images/colored_m.png" alt="Colored Logo" />
                       </div> 
                    </div>
                </div>

                <div className={styles.centered_image}>
                    <img src="/images/upcoming_image.png" alt="" />
                </div>

                <div className={styles.waitlist}>
                    <h2>Want to take it for a ride?</h2>
                    <div className={styles.aligner}>
                    <input type="text" />
                    {/* <button className={styles.upcom_btn}>Join The Waitlist</button> */}
                    <div className={styles.upcom_btn}><img src={joinListBtn}/></div>
                    </div>
                </div>
            </div>
        </>
    )}