import styles from "../styles/inshorts.module.css";

export default function Inshorts() {
	return (
		<>
			<div className={styles.headingWrapper}>
				<p className={styles.short_heading}>About HYPR4 </p>
				<div className={styles.line}></div>
			</div>
			<div className={styles.inshorts_left}>
					<h1 className={styles.inshorts_head}>In Short</h1>
			</div>
			<div className={styles.container}>
				<div className={styles.inshorts_left}>
					<div className={styles.inshorts_desc}> 
					<p>
						HYPR4 leads the industry in <strong>Google Cloud solutions</strong>,
						transforming complexity into simplicity </p><br />
						<p>Our certified experts specialize in advanced <strong>migrations,
						data science</strong>, and cutting-edge <strong>cloud management.</strong></p> <br />
						<p>We deliver <strong>precise, secure,</strong> and <strong>cost-optimized
						solutions</strong>, ensuring your business thrives in the digital
						era.</p> <br />
						<div className={styles.icons}>
						<img id={styles.google}
							src="/images/google_cloud.png"
							alt="Google Cloud"
						/>
						<img id={styles.mongodb}
							src="/images/mongo_db.png"
							alt="Mongo DB"
						/>
					</div>
					</div>
					
				</div>

				<div className={styles.inshorts_right}>
					<img
						src="/images/inshort_img.png"
						alt="Inshorts Image"
					/>
				</div>
			</div>
		</>
	);
}