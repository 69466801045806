import Slider from '../components/Slider';
import styles from '../styles/about.module.css';
import getInTouchBtn from "../assets/images/getInTouchBtn.svg";


const About = () => {
    return (
       <>
        <div className={styles.container}>
            <div className={styles.left_div}>
                <div className={styles.m_logo}><img src="/images/m_logo.png" alt="Company logo"/></div>
                <h1 className={styles.heading}>We Redefine Cloud Possibilities</h1>
                <p className={styles.desc}>We transform enterprises with cutting-edge Google Cloud services, ensuring future readiness.</p>
                {/* <button className={styles.cont_btn}>Get In Touch  </button>   */}
                <div className={styles.cont_btn}><img src={getInTouchBtn} /></div>
            </div>

            <div className={styles.right_div}>
                <img src="\images\about_hero.png" className={styles.right_img} alt="Hero Image"/>
            </div>
        </div>

        <Slider />
        
        <div>
        <div className={styles.headingWrapper}>
				<p className={styles.short_heading}>About HYPR4 </p>
				<div className={styles.line}></div>
			</div>
			<div className={styles.inshorts_left}>
					<h1 className={styles.inshorts_head}>Why we do what we do</h1>
			</div>
			<div className={styles.container}>
				<div className={styles.inshorts_left}>
					<div className={styles.inshorts_desc}> 
					<p>
                    At HYPR4, we thrive on pushing the boundaries of what's possible in the cloud. </p><br />
                    <p>Our mission is to empower businesses to achieve unparalleled efficiency, scalability, and security. </p><br />
                    <p>With the leaders who helped Fortune 500 companies, we leverage our deep expertise in 
                    Google Cloud Platform (GCP) to deliver end-to-end solutions that drive tangible results. 
                    From big data to AI, our team of certified professionals is committed to guiding your enterprise through every stage of cloud adoption and optimization.</p>
						<div className={styles.icons}>
						<img id={styles.google}
							src="/images/google_cloud.png"
							alt="Google Cloud"
						/>
						<img id={styles.mongodb}
							src="/images/mongo_db.png"
							alt="Mongo DB"
						/>
					</div>
					</div>
					
				</div>

				<div className={styles.inshorts_right}>
					<img
						src="\images\Deconstructed-bro 3.png"
						alt="Inshorts_Image"
					/>
				</div>
			</div>
            <div className={styles.block_main}>
                    <div className={styles.block_container}>
                        <div className={styles.leftDiv}><img src="\images\VisionImg.png" alt='union_img'></img></div>
                        <div className={styles.rightdiv}>
                        <h1 className={styles.heading}>Vision</h1>
                        <p className={styles.desc}>To be the global leader in cloud optimization, enabling businesses to harness 
                            the full potential of Google Cloud through innovative and scalable solutions. </p>
                        </div>
                    </div>
                    <div className={styles.block_container}>
                        <div className={styles.leftDiv}><img src="\images\MissionImg.png" alt='union_img'></img></div>
                        <div className={styles.rightdiv}>
                        <h1 className={styles.heading}>Misson</h1>
                        <p className={styles.desc}>To deliver transformative cloud solutions that empower enterprises to achieve their strategic goals.
                         We are committed to continuous innovation, excellence in execution, and building lasting partnerships with our clients. </p>
                        </div>
                    </div>
                </div>
        </div>

            <div className={styles.i4}>
            <div className={styles.headingWrapper}>
				<p className={styles.short_heading}>Our Roots </p>
				<div className={styles.line}></div>
			</div>
			<div className={styles.inshorts_left}>
					<h1 className={styles.inshorts_head}>The I4</h1>
			</div>
            <div className={styles.grid_wrapper}>
                <div className={styles.grid_content}>
                <div className={styles.gridItem1}>
                    <div className={styles.gridText}>
                    <h3>Impact</h3>
                    <p>We prioritize our clients' needs, focusing on delivering positive outcomes that drive significant business growth.</p>
                    </div>
                    <div>
                    <div className={styles.gridImg}>
                        <img src="\images\ImpactImg.png" alt='Impact_Img'/>
                        </div>
                    </div>
                </div>
                <div className={styles.gridItem2}>
                    <div className={styles.gridText}>
                    <h3>Integrity</h3>
                    <p>We build trust through transparency, reliability, and a commitment to our clients' success.</p>
                    </div>
                    <div className={styles.gridImg}>
                    <img src="\images\IntegrityImg.png" alt='Integrity_Img'/>
                    </div>
                </div>
                <div className={styles.gridItem3}>
                <div className={styles.gridText}>
                    <h3>Inclusivity</h3>
                    <p>We foster a culture of respect, ensuring every team member and client is valued and heard.</p>
                    </div>
                    <div className={styles.gridImg}>
                    <img src="\images\InclusivityImg.png" alt='Inclusivity_Img'/>
                    </div>
                </div>
                <div className={styles.gridItem4}>
                <div className={styles.gridText}>
                    <h3>Innovation</h3>
                    <p>Our team of certified Google Cloud experts is dedicated to delivering the highest standards of quality and performance in every project.</p>
                </div>
                <div className={styles.gridImg}>
                <img src="\images\InnovationImg.png" alt='Innovation'/>
                </div>
                </div>
            </div>
            </div>
            </div>

            <div>
            {/* <div className={styles.headingWrapper}>
				<p className={styles.short_heading}>Our Roots </p>
				<div className={styles.line}></div>
			</div>
			<div className={styles.inshorts_left}>
				<h1 className={styles.inshorts_head}>Leaders From</h1>
			</div> */}
            {/* <div className={styles.slider}>
                <div className={styles.slide_track}>
                    <div className={styles.slide}>
                        <img src="\images\Oxfoard_img.png" alt="oxfoard" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\NDTV_img.png" alt="ndtv" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\DELL_img.png" alt="Dell" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\ZEE_img.png" alt="Zee" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\oracle_img.png" alt="oracle" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\indian_express.png" alt="indianexpress" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\Oxfoard_img.png" alt="oxfoard" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\NDTV_img.png" alt="ndtv" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\DELL_img.png" alt="Dell" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\ZEE_img.png" alt="Zee" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\oracle_img.png" alt="oracle" />
                    </div>
                    <div className={styles.slide}>
                        <img src="\images\indian_express.png" alt="indianexpress" />
                    </div>
                </div>
            </div> */}
            </div>
       </>
    );
};

export default About;