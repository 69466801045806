import styles from "../styles/grid_view.module.css";

export default function Grid_view() {
	return (
		<>
			<div className={styles.grid_container}>
				<div className={styles.headingWrapper}>
					<p className={styles.short_heading}>Our Best Points</p>
					<div className={styles.line}></div>
				</div>
				<div className={styles.main_title}>Your Best Bet - Us!</div>

				<div className={styles.grid_wrapper}>
					<div className={styles.grid_content}>
						<div className={styles.gridItem1}>
							<div className={styles.google_content}>
								<img
									src="/images/Googlecloud.png"
									alt="Api Icon"
								/>
								<h2>Expertise in Google Cloud</h2>
							</div>
							<p>
								Certified experts leveraging the full spectrum
								of Google Cloud services to deliver scalable,
								secure, and tailored solutions.
							</p>
						</div>
						<div className={styles.gridItem2}>
							<div className={styles.cost_optimize}>
								<img
									src="/images/cost_optimize.png"
									alt="Cost Optimization"
								/>
								<h2>Cost Optimization Specialists</h2>
							</div>
							<div className={styles.cost_content}>
								<p>
									Reduce costs while maintaining high
									performance with actionable insights and
									recommendations aligned with best cloud cost
									management practices.
								</p>
							</div>
						</div>
						<div className={styles.gridItem3}>
							<div className={styles.dataAnalytics}>
								<img
									src="/images/advance_AI.png"
									alt="Data Analytics"
								/>
								<h2>Advanced Data Analytics and AI</h2>
							</div>
							<div className={styles.data_content}>
								<p>
									Transform raw data into actionable insights
									using Google Cloud’s cutting-edge tools,
									empowering data-driven decisions.
								</p>
							</div>
						</div>
						<div className={styles.gridItem4}>
							<div className={styles.record_div}>
								<img
									src="/images/track_record.png"
									alt="record track"
								/>
								<h2>Proven Track Record</h2>
							</div>
							<p>
								Successful projects and satisfied clients
								highlight our ability to deliver high-quality
								Google Cloud solutions driving business growth
								and innovation.
							</p>
						</div>
						<div className={styles.gridItem5}>
							<div className={styles.security_div}>
								<img
									src="/images/security_approch.png"
									alt="Security_First"
								/>
								<h2>Security-First Approach</h2>
							</div>
							<p>
								Robust security measures embedded in all
								solutions to protect your data and applications
								from potential threats.
							</p>
						</div>
						<div className={styles.gridItem6}>
							<div className={styles.innovate_div}>
								<img
									src="/images/innovative_cloud.png"
									alt="Innovation"
								/>
								<h2>Innovative Cloud Management</h2>
							</div>
							<div className={styles.innovate_content}>
								<p>
									Unparalleled visibility and control over
									your cloud environment with the latest
									management tools, ensuring optimal
									performance and resource utilization.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}