import styles from "../styles/hero.module.css";
import Herobtn from "../assets/images/Herobtn.svg"

export default function HeroComponent(){
    return(
        <>
        <div className={styles.container}>
            <div className={styles.left_div}>
                <div className={styles.m_logo}><img src="/images/m_logo.png" alt="Company logo"/></div>
                <h1 className={styles.heading}>Your Cloud Partner to Manage + Optimize!</h1>
                <p className={styles.desc}>Setting the Benchmark for Optimized, Scalable, and Innovative Google Cloud Solutions, Guiding Your Path to Digital Leadership</p>
                <div><img src={Herobtn}/></div>
                {/* className={styles.cont_btn} */}
            </div>

            <div className={styles.right_div}>
                <img src="/images/hero_img.png" className={styles.right_img} alt="Hero Image"/>
                <img src="/images/leaves.png" className={styles.leaf} alt="leaves"/>
            </div>


        </div>
        </>
    )
}